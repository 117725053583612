.accordion-button:not(.collapsed) {
    background-color: rgba(128, 128, 128, 0.15) !important;
    color: black !important;
    border-color: black !important; /* Umrandungsfarbe */
    border-width: 1px !important; /* Schmalere Umrandung */
}

/* Entferne den Schatten im Fokus-Zustand */
.accordion-button:focus {
    box-shadow: none !important; /* Kein Schatten */
    border-color: black !important; /* Umrandungsfarbe */
}

