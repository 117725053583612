/* EvaluationForm.css */

.radio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
}

.radio {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    background-color: #fff;
    border-radius: 75%;
    position: relative;
    border: 2px solid #000;
    cursor: pointer;
}

.radio.selected:after {
    content: '';
    display: block;
    width: 75%;
    height: 75%;
    background-color: green;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.label-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 40%;
    margin-right: 1em;
}

.options {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 60%;
    flex-wrap: wrap;
}

.form-container {
    max-width: 900px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin: 0 auto;
    padding: 25px;
    background: white;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 2em;
}

.section {
    margin-bottom: 40px;
}

.question {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1em;
    font-size: large;
}

.question .label {
    flex-basis: 100%;
    text-align: left;
    padding-right: 1em;
    margin-bottom: 1em;
}

.options .radio-container:last-of-type label,
.options .radio-container:last-of-type input[type="radio"] {
    color: #A80000;
}

.question .options {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.option {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-right: 10px;
    font-size: medium;
    color: green;
}

.option:last-child {
    color: #A80000;
    font-weight: normal;
    font-size: medium;
}

.error-message {
    color: #A80000;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: medium;
}

.textarea {
    margin-bottom: 2rem;
    width: 100%;
}

.textarea label {
    display: block;
    margin-bottom: 10px;
}

.textarea textarea {
    width: 100%;
    min-height: 100px;
}

button[type="submit"] {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background: green;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
}

button[type="submit"]:hover {
    background-color: white;
    color: green;
    border: 1px solid green;
    border-radius: 4px;
}

button[type="submit"]:disabled {
    background: grey;
    color: white;
    cursor: not-allowed;
}

@media (min-width: 768px) {
    .question {
        flex-wrap: nowrap;
    }

    .question .label,
    .question .options {
        flex-basis: 50%;
    }
}
