.modal-90w {
	width: 90%;
	display: inline-flexbox;
}

.card-footer a {
	cursor: zoom-in;
	text-decoration: none !important;
	color: #a80000 !important;
}

.modal-header {
	background-color: #a80000; 
	color: white;
	opacity: 1;
}

.btn-close { 
	opacity: 1 !important; 
}

.link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: none;
	color: #a80000;
	display: inline;
	margin: 0;
	padding: 0;
}

p .hyphens {
	hyphens: auto;
}
