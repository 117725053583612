/* styles.css */
.table {
    width: 100%;
    border-collapse: collapse;
}

.table td,
.table th {
    padding: 10px;
    border-bottom: 1px solid #BBB;
}