body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'BerlinTypeRegular', 'BerlinTypeBold', 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
	font-family: 'BerlinTypeRegular';
	src: local('BerlinTypeRegular'), url(fonts/BerlinTypeWeb-Regular.woff) format('woff'),
		url(fonts/BerlinTypeWeb-Regular.woff2) format('woff2');
}

@font-face {
	font-family: 'BerlinTypeBold';
	src: local('BerlinTypeBold'), url(fonts/BerlinTypeWeb-Bold.woff) format('woff'),
		url(fonts/BerlinTypeWeb-Bold.woff2) format('woff2');
}

a {
	text-decoration: none !important;
}
