.no-wrap {
	white-space: nowrap;
}
.active-link {
	color: darkred !important; /* Beispiel-Farbe für den aktiven Link */
	font-weight: bold !important;
}

/* CSS-Anpassungen in der navigation.css Datei */
.fixed-logo {
	height: 45px !important;
	width: auto !important;
	max-width: none !important;
	flex-shrink: 0;
}
